/* eslint-disable max-len, vue/max-len */
/**
 * @file 出海易商家服务协议
 * @author dingxin02@baidu.com
 */

export const data = {
    'zh-CN': {
        title: '出海易商家服务协议',
        // 版本号
        version: '202203-V1',
        // 范本编号
        templateNo: 'FBOT20220069',
        publishTime: '版本日期：2021-09-30',
        content: `
            <p>欢迎您使用出海易提供的服务！</p>
            <p>本协议是您（以下或称&ldquo;商家&rdquo;）与出海易共同签署。本协议阐述条款和条件适用于您使用出海易向您提供的各项服务，包括出海易网站（chuhaiyi.baidu.com）、出海易SaaS后台（chuhaiyi.baidu.com/saas/）等在内的各种形态（包括未来技术发展出现的新的服务形态）。&ldquo;商家&rdquo;是指登录、使用出海易各项服务/软件的机构或组织，在本协议中亦称为&ldquo;您&rdquo;。</p>
            <p><strong><strong>一、接受协议</strong></strong></p>
            <ol>
            <li>本协议的内容包括以下条款及出海易平台已经发布的或将来可能发布的各项规则，所有规则为本协议不可分割的一部分，与协议正文具有同等法律效力。<br />您在使用出海易服务之前，应当认真阅读本协议。<strong><u><strong>请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。</strong></u></strong>如您对协议有任何疑问，可通过邮箱chuhaiyi@baidu.com与出海易联系。<strong><strong>在您登录时，您确认您已经阅读、理解并接受本协议的全部条款及各项规则，并承诺遵守中国</strong></strong><strong><strong>及您业务所涉及的国家</strong></strong><strong><strong>的各类法律、法规、政策的规定，如有违反而导致任何法律后果的发生，您将以自己的名义独立承担所有相应的法律责任。</strong></strong></li>
            <li><strong><strong> 出海易平台有权根据相关法律、法规、政策的要求及提供服务的需要不时地修改本协议或根据本协议制定、修改各类具体规则并在出海易平台相关版块发布，请您适时注意本协议及具体规则的变更。若您在本协议及具体规则内容公告变更后继续使用出海易平台的各项服务/软件，表示您已经阅读、理解并接受修改后的协议和具体规则内容，也将遵循修改后的协议和具体规则使用出海易各项服务/软件；同时就您在协议和具体规则修订前通过出海易平台进行的商业活动行为及其效力，视为您已同意并已按照本协议及有关规则进行了相应的授权和追认。若您不同意修改后的协议内容，您应立即停止使用出海易相关的服务。</strong></strong></li>
            </ol>
            <p><strong><strong>二、协议范围</strong></strong></p>
            <ol>
            <li><strong><strong> 本协议由您与出海易共同缔结，本协议对您与出海易均具有合同效力。</strong></strong></li>
            <li><strong><strong> 您通过自行或授权有关方根据本协议及出海易平台有关规则、说明操作确认本协议后，本协议即在您和出海易平台之间产生法律效力。本协议不涉及您与出海易平台的其他商家或用户之间产生的法律关系或法律纠纷。</strong></strong></li>
            <li>由于互联网高速发展，您与出海易签署的本协议列明的条款并不能完整罗列并覆盖您与我们所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。<strong><u><strong>因此，出海易（chuhaiyi.baidu.com&nbsp;）站内的各项规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您继续使用出海易平台服务内容，视为您同意上述补充协议。</strong></u></strong></li>
            </ol>
            <p><strong><strong>三、定义</strong></strong></p>
            <ol>
            <li><strong><strong> 出海易：</strong></strong>指为中国出口商家提供一站式多元化出海营销解决方案服务平台，旨在帮助中国出口商家获取海外商机及高效开展跨境出海业务，包含出海易网站（chuhaiyi.baidu.com）PC端以及后期可能开发的移动端、APP等。</li>
            </ol>
            <p>2.出海易服务：出海易基于互联网，以包括出海易网站等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。您具体享有的服务以您实际选择并付费的出海易服务为准，本协议中列举的服务为平台整体提供的全部服务，不代表您可以使用全部类型服务。</p>
            <ol start="3">
            <li>出海易平台规则：包括在所有出海易平台规则频道内已经发布及后续发布的全部规则、解读、公告等内容。</li>
            </ol>
            <p><strong><strong>四、账号注册与使用</strong></strong></p>
            <ol>
            <li><strong><strong> 商家资格</strong></strong></li>
            </ol>
            <p>1) 您确认，在您完成注册程序时，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。<strong><strong>若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的相应后果，且出海易有权终止向您提供服务并注销您的账户。</strong></strong>如您代表一家公司或其他法律主体在出海易注册，则您声明和保证，您有权使该公司或该法律主体受本协议的约束。</p>
            <p>2) 此外，您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用服务。</p>
            <ol start="2">
            <li><strong><strong> 账户注册</strong></strong></li>
            </ol>
            <p>1)在您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他出海易平台允许的方式实际使用出海易服务时，您即受本协议的约束。</p>
            <p>2）由于您的账户关联您的信息及出海易平台商业信息，您的账户仅限您本人使用。未经出海易同意，您直接或间接授权第三方使用您账户或获取您账户项下信息的行为无效。如出海易根据您账户的使用行为等情况判断您账户的使用可能危及您的账户安全及/或出海易信息安全的，出海易可拒绝提供相应服务或终止本协议。</p>
            <ol start="3">
            <li>认证</li>
            </ol>
            <p>为使您更好地使用出海易的各项服务，出海易可要求您按出海易规定或者按国家法律法规的规定完成真实性认证或其他实名认证。</p>
            <ol start="4">
            <li>注册信息管理</li>
            </ol>
            <p>1) 完成注册时，您应当准确完整地提供并及时更新您的信息，以使之始终保持真实、及时，完整和准确。在国家法律法规有明确规定要求出海易为平台服务提供者必须对商家的信息进行核实的情况下，出海易将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</p>
            <p>2)&nbsp;如出海易按您最后一次提供的信息与您联系未果、您未按出海易的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及出海易造成的全部损失与不利后果。</p>
            <ol start="5">
            <li>账户安全</li>
            </ol>
            <p>1)&nbsp;您的账户由您保管，出海易任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，出海易<strong><strong>并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</strong></strong></p>
            <p><strong><strong>2)&nbsp;</strong></strong>除出海易存在过错外，<strong><strong>您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、</strong></strong><strong><strong>发送邮件、独立站建站、披露信息</strong></strong><strong><strong>等）负责。</strong></strong></p>
            <p>3) 您必须通过出海易平台来访问或使用出海易各项服务/软件，对于通过其它手段登录、使用所造成的后果，出海易将不承担任何责任。您按照要求填写账号和密码并进行使用之后，即受本协议的约束。您应当保护自身的密码安全，并对所有在商家帐户下使用的行为包括但不限于商家自身和任何第三方在商家帐户下的行为承担全部责任。</p>
            <p>4) 如您一旦发现账户存在未经商家授权使用的行为，应立即通知出海易。出海易平台有权在其服务器上复制保存您的信息并通知给相关联公司或平台，并用之为了您的利益与您联络。您应理解出海易对您的任何请求采取行动均需要合理时间，且出海易应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除出海易存在法定过错外，您账户遭窃、遗失的情况，出海易不承担责任。</p>
            <p>5) 您了解并同意，为了帮助您更好地使用出海易平台所有服务/软件，出海易平台有权获取您及您的用户方如下信息：您在享受各项服务过程中产生的各类信息。您同意授权出海易平台及出海易平台的合作方可以使用以上信息进行相关分析、改善出海易平台各项产品的内容，并使相关产品/服务的内容、设计和服务更能符合商家的需求。</p>
            <p>6) 商家取得了出海易平台帐号并不意味获得全部百度产品的授权，仅仅是取得了出海易平台服务/软件的身份，商家在登录百度的相关网页、加载应用、下载安装软件时需要另行签署单个产品的授权协议。</p>
            <p>7) 出海易平台依据您填写和提交的帐号资料，在法律允许和协议范围内向其他用户展示您的注册信息。如您提供的服务或内容需要取得相关法律法规规定的许可或进行备案的，您应当在帐号注册与认证时进行明确说明并提交相应的合法有效的许可或备案证明。否则，出海易平台有权拒绝或终止提供本服务，并依照本协议对违规帐号进行处罚。因此给出海易及其关联公司或第三方造成损害的，您应当依法予以赔偿。上述资料如有任何变更，您应立即向出海易平台书面提交变更后资料。变更资料未经核实前，出海易平台可完全依赖变更前的资料行事，由此产生的一切风险由您自行承担。如果出海易平台自行发现，或第三方通知发现您提供的上述信息不真实或不准确的，百度公司有权终止向您提供本服务，由此产生的一切法律责任由您自行承担。</p>
            <p><strong><strong>8) 您理解并同意，出海易平台将基于您提交包括但不限于注册信息、服务类目、使用说明等资料，在出海易的合法权限和能力范围内综合作出合理、谨慎的形式评估和判断服务。但百度公司无法实质审查商家的实际经营、运营以及推广等行为，并不为此提供任何担保；也不应因其审核行为而被认定为是商家行为或商家内容的共同提供方，且不因该等审核而对您的行为及内容承担任何责任。您应仔细阅读有关协议、规范和规则，由于客观存在不断变化的法律法规、监管要求、产品规范和用户体验需求等各类因素，您的部分产品、软件功能有可能被暂停或停止。</strong></strong></p>
            <p>9) 您理解并同意，出海易平台服务/软件开发及商家审核将对出海易平台产生人力和经济负担，若您在出海易服务/软件使用期内，恶意或非恶意（如技术水平、商家需求等）原因而导致过于频繁提交信息内容，出海易及/或关联公司有权对您采取要求支付服务费/保证金、暂停或终止向您提供信息内容发布等措施，由此产生的责任由您独自承担。</p>
            <p>10) 您清楚理解并同意，您通过出海易平台上传、管理、发布的信息内容都应当在法律允许及出海易平台许可的类目范围内，且须在您的核定经营范围和法定资格备案范围内。为此，您应当真实、全面、不遗漏地根据您通过出海易平台所提供的服务、从事的活动及所在的行业管理您的信息内容。若出海易平台发现您提交的资质材料不符合相关行业的资质材料要求，将暂停或停止您的账号权限。同时，基于特定行业、特定领域的法律法规或监管要求，出海易平台也可能根据不断变化的现实情况要求您补充提供相关资质材料，对此您应配合提供，否则出海易平台有权拒绝向您提供服务。</p>
            <p><strong><strong>五、商家使用规范</strong></strong></p>
            <ol>
            <li><strong><strong> 您理解并同意，您不得制作、复制、发布、传播、干扰出海易平台正常运营以及侵犯其他商家/用户或第三方合法权益的内容，包括但不限于下列行为：使用、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助出海易平台发展与之有关的衍生产品、服务等。</strong></strong></li>
            <li><strong><strong> 您理解并同意，您应该按照出海易平台的规则</strong></strong><strong><strong>通过出海易平台</strong></strong><strong><strong>提交或上传公司信息及商品信息</strong></strong><strong><strong>并发布至您的独立站</strong></strong><strong><strong>，包括但不限于公司名称、发货地、联系人姓名、联系人手机号、座机号、邮箱、传真号、商品信息介绍等，并同意出海易平台有权根据平台规则将您提交的前述信息在出海易相关产品上明文直接展示，包括但不限于出海易平台的PC端等出海易相关产品。</strong></strong></li>
            <li><strong><strong> 您不得发布、传送、传播、储存违反国家法律法规禁止的内容，包括但不限于：</strong></strong></li>
            </ol>
            <p>（1）违反宪法确定的基本原则的；<br />（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />（3）损害国家荣誉和利益的；<br />（4）煽动民族仇恨、民族歧视，破坏民族团结的；<br />（5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br />（6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br />（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；<br />（8）侮辱或者诽谤他人，侵害他人合法权益的；<br />（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；<br />（10）以非法民间组织名义活动的；<br />（11）含有法律、行政法规禁止的其他内容的。</p>
            <ol start="4">
            <li>您不得发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密、隐私权等合法权利的内容。</li>
            <li>您不得发表、传送、传播骚扰信息、垃圾信息或含有任何性或性暗示的内容。</li>
            <li>您不得发表、传送、传播任何涉及暴力、赌博、危害安全、涉黑、过度营销、谣言、误导等违反法律法规、政策及公序良俗、社会公德或干扰出海易平台正常运营和侵犯其他商家/用户或第三方合法权益内容的信息。您不得将出海易平台以任何形式作为从事各种非法活动的场所、平台或媒介。</li>
            <li>我们保留在任何时候为您提供出海易平台的修改、升级的权利，以及为修改或升级服务收取费用的权利。</li>
            <li>出海易平台在此郑重提请商家注意，任何商家通过出海易平台开发、创建、上传、编辑、发布的各类信息内容，均由商家对其开发、创建、上传、编辑、发布的行为和信息内容自行承担责任。出海易无法控制商家展示之内容，也无法对商家的行为进行全面控制，因此不能保证商家内容的合法性、正确性、完整性、关联性、真实性或品质；如果商家内容可能使他人接触到令人不快、不适当甚至违法违规等信息，相关风险全部由商家自行承担，与出海易平台无关。商家若散布、传播反动、色情或其他违反国家法律、法规的信息，商家发布的各类信息可能作为商家违法的证据。</li>
            <li>您不得利用任何非法手段获取其他商家或用户的个人信息，不得将其他商家/用户的信息用于任何营利或非营利目的，不得泄露其他商家或权利人的个人信息，否则出海易平台有权采取本协议规定的合理措施制止您的上述行为，情节严重的，将提交公安机关进行刑事立案。</li>
            <li>您不得发布、传送、传播、储存、侵害任何侵犯他人著作权、商标权等知识产权、商业秘密或其他合法权利（如肖像权、隐私权）的内容；对于转载的信息，您应写明转载和出处，标明作者，尊重作者的版权；如果有其他商家或权利人发现您发布的信息涉嫌知识产权、或其他合法权益争议的，这些商家或权利人有权要求出海易平台删除您发布的信息，或者采取其他必要措施予以制止，出海易平台将会依法采取这些措施。因您侵权行为所造成的一切责任，均由您独自承担由此而产生的一切法律责任，与出海易平台无关。</li>
            <li><strong><strong> 您同意如您使用出海易平台为任何第三方提供服务，对于您和第三方之间的纠纷，出海易平台将不承担任何责任。</strong></strong></li>
            <li><strong><strong> 您承诺通过出海易平台进行的活动所引发的一切法律后果，由您承担全部责任。如因您使用出海易平台的行为，导致出海易平台、百度公司或任何第三方为此承担了相关的责任，则您需全额赔偿出海易平台、百度公司或任何第三方的相关支出及损失，包括合理的律师费用。</strong></strong></li>
            <li><strong><strong> 如您违反本协议规定的任何条款，给出海易、百度公司或其他商家造成损失，您必须承担全部的赔偿责任。如出海易及/或百度公司承担了上述责任，则您同意赔偿出海易及/或百度公司的相关支出和损失，包括合理的律师费用。</strong></strong></li>
            <li><strong><strong> 您应不时关注并遵守出海易平台定期公布或修改的各类规则规定。出海易平台保有删除站内各类不符合法律政策或不真实的信息内容而无须通知您的权利。</strong></strong></li>
            </ol>
            <p><strong><strong>六、出海易服务</strong></strong></p>
            <p>您有权在出海易上享受浏览出海易官网内容及资讯、独立站建站就信息上传与发布、线索管理、邮件管理、线上推广等服务。您实际享有的服务以您选择和订购的产品提供的服务为准。</p>
            <ol>
            <li><strong><strong>浏览出海易官网内容及资讯</strong></strong></li>
            </ol>
            <p>您可以浏览出海易官网的内容及资讯、注册账号后登录出海易后台、了解出海易相关产品以及服务信息。您也可以留言并留下联系方式，以便与我们合作的第三方服务商和您取得联系并向您介绍出海易相关产品及服务。</p>
            <ol start="2">
            <li><strong><strong>2</strong></strong><strong><strong>.</strong></strong><strong><strong>独立站建站及信息上传与发布</strong></strong></li>
            </ol>
            <p>1) 通过在出海易平台提供的独立站建站技术服务，您可搭建属于您的独立站，在您的独立站上传发布各类产品及/或服务信息，您可以对自己的独立站进行管理和运营。</p>
            <p>2) 通过出海易平台提供的服务，您有权通过文字、图片等形式在您的独立站发布产品及/或服务信息。</p>
            <p>3) 您应当确保您对您在独立站上发布的产品及/或服务享有相应的权利，您不得在独立站及出海易平台上发布或展示以下产品及/服务：</p>
            <p><strong><strong>（1）国家禁止或限制的；</strong></strong><strong><strong><br /></strong></strong><strong><strong>（2）侵犯他人知识产权或其它合法权益的；</strong></strong><strong><strong><br /></strong></strong><strong><strong>（3）出海易平台规则、公告、通知或各平台与您单独签署的协议中已明确说明不适合在出海易平台上</strong></strong><strong><strong>上传、发布或</strong></strong><strong><strong>展示的。</strong></strong></p>
            <p>4) 您应当遵守诚实信用原则，确保您所发布的产品及/或服务信息真实、与您实际所提供的产品及/或提供的服务相符，并在与用户交易过程中切实履行您的承诺。您不得以任何方式干扰或试图干扰出海易平台的正常运作。</p>
            <p>5) 依法纳税是每一个公民、企业应尽的义务，您应按照法律的要求及时、足额地向税务主管机关申报纳税。</p>
            <p><strong><strong>6) 出海易有权对您的注册数据、</strong></strong><strong><strong>运营</strong></strong><strong><strong>行为及相关数据进行查阅，发现注册数据、</strong></strong><strong><strong>运营</strong></strong><strong><strong>行为及数据中存在任何问题，均有权向您发出询问或要求改正的通知，或者直接作出删除等处理。</strong></strong></p>
            <p>7) 为帮助商家提升内容质量，平台可能提供功能模块，供商家优化内容质量、提升网络沟通效率等。平台提供的功能模块包括但不限于客户管理、内容管理、独立站管理、邮件管理等，不同功能模块具有不同的使用要求和标准。商家使用各功能模块生成的文字、图片等各类型内容统称为&ldquo;商家内容&rdquo;。<strong><strong>根据商家需求、技术发展、业务调整等情况的变化，平台实时增减、调整各类功能及其关联的产品服务，实际可用功能及功能要求以平台展示为准。</strong></strong></p>
            <p>8) 某些功能模块可能会提供图片或文字素材便于商家学习、研究，商家可以随时对这些素材内容进行修改，出海易无法保证这些素材内容与商家实际情况或实际需求的关联性及准确性。请商家认真复核最终展现内容，确保其不违反任何生效的法律法规，且与商家的实际情况相符合。</p>
            <ol start="3">
            <li>3.线索管理，您可通过新建、上传、导入、独立站获得线索等方式在线索管理中新增线索，并通过状态变更、新增备注等操作对线索进行跟进管理</li>
            <li>邮件管理，您可在邮件模块中，通过邮件状态变更、删除等功能管理您的邮件。</li>
            <li>线上推广，您自行提供相关推广物料，通过出海易及第三方线上推广公司提供的服务，为您进行线上推广服务。</li>
            <li><strong><strong> 数据的储存</strong></strong></li>
            </ol>
            <p><strong><strong>对于商家在出海易平台发表或存储的内容，商家应当自行不定期进行备份。出海易不对您在本软件中相关数据的删除或储存失败负责。出海易有权根据实际情况自行决定单个商家在本软件中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本软件中的相关数据。如果您停止使用出海易或服务被终止或取消，出海易及/或关联公司可以从服务器上永久地删除您的数据。在服务停止、终止或取消后，百度公司没有义务向您返还任何数据或出海易平台的内容。</strong></strong></p>
            <p><strong><strong>七、费用</strong></strong></p>
            <ol>
            <li>百度为出海易平台向您提供的服务付出了大量的成本，除出海易平台明示的收费业务外，出海易向您提供的其他服务目前是免费的。如未来出海易向您收取合理费用，出海易会采取合理途径并以足够合理的期限提前通过法定程序并以本协议约定的方式通知您，确保您有充分选择的权利。</li>
            <li><strong><strong> 对于出海易平台明示的收费业务，如因您或者您的原因导致出海易无法正常履行约定服务时，出海易不承担任何责任，且无法为您变更服务周期或退款。</strong></strong></li>
            <li>您因进行交易、向出海易获取有偿服务或接触出海易服务器而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。</li>
            </ol>
            <p><strong><strong>八</strong></strong><strong><strong>、商家信息的保护及授权</strong></strong></p>
            <ol>
            <li>保护商家个人信息是百度公司的一项基本原则，百度公司将会采取合理的措施保护商家的个人信息。除法律法规规定的情形外，未经商家许可百度公司不会向第三方公开、透露商家个人信息。在您使用出海易提供的服务时，您同意百度公司按照在出海易平台上公布的《隐私政策》收集、存储、使用、披露和保护您的个人信息。百度公司对相关信息采用专业加密存储与传输方式，保障商家个人信息的安全。</li>
            <li><strong><strong> 您在申请出海易服务过程中，需要填写一些必要的信息，请保持这些信息的真实、准确、合法、有效并注意及时更新，同时您理解并同意，您授权出海易在您使用账户登录及使用出海易服务过程中提供、形成的信息传给百度其他相关平台，以便百度公司向您提供及时有效的帮助，或更好地为您提供服务。根据相关法律法规和政策，请您填写真实的身份信息。若您填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。您理解并同意，为了向您提供服务，需要在法律允许的范围内向其他商家/</strong></strong><strong><strong>用户</strong></strong><strong><strong>展示您的注册信息。</strong></strong></li>
            <li>您声明并保证，您对您所发布的信息拥有相应、合法的权利。<strong><strong>否则，出海易可对您发布</strong></strong><strong><strong>在出海易</strong></strong><strong><strong>的信息依法或依本协议进行删除或屏蔽。</strong></strong></li>
            <li>您在使用出海易平台的过程中，应严格遵守所适用的关于保护用户信息、个人信息和隐私权及保障网络安全运行等方面的法律法规。您的产品、服务具有收集个人信息（包括个人信息含个人敏感信息的，其中&ldquo;个人敏感信息&rdquo;例如：反映个人财产状况、个人健康生理、个人身份、个人生物识别、网络身份标识、个人电话号码、通信记录和内容、位置状况的数据等以及未成年人个人信息）功能的，应当遵循合法、正当、必要的原则，向被收集信息主体明示收集、使用信息的目的、方式和范围，并经被收集信息主体明示同意。</li>
            <li>百度公司将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</li>
            <li>未经您的同意，百度公司不会向百度公司以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。</li>
            <li>百度公司非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用出海易的服务前，应事先取得您家长或法定监护人的书面同意。</li>
            <li>您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，出海易可对您发布的信息依法或依本协议进行删除或屏蔽。</li>
            <li>对于您提供、发布及在使用出海易服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，在法律规定的保护期限内您免费授予出海易及百度公司获得全球排他的许可使用权利及再授权给其他第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。您同意出海易及百度公司存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</li>
            <li>您保证对从出海易平台获取的、经过信息主体授权的个人信息进行加密存储并在信息主体授权范围内使用个人信息，不得在未经信息主体明示同意或超出其同意范围的情况下使用、共享、转让和披露信息主体的个人信息；您确保能够建立足够的数据安全能力并采取必要的管理和技术手段，按照国家标准实行个人信息安全保护措施，防止个人信息的泄露、损毁和丢失。同时，未经百度公司允许或在超出百度公司授权范围的情况下，您不得收集、处理、存储、抓取或以任何方式获取百度公司控制的信息主体的个人信息。</li>
            <li>如您违反上述个人信息保护条款或出海易判断您收集、处理、使用、共享个人信息的行为可能损害个人信息主体权益时，您应按照出海易的要求立即删除相关的个人信息数据，并不再收集、处理、存储、使用上述个人信息数据。</li>
            </ol>
            <p><strong><strong>九</strong></strong><strong><strong>、知识产权</strong></strong></p>
            <ol>
            <li>商家在遵守法律及本协议的前提下可依据本协议使用出海易平台。商家无权实施包括但不限于下列行为：</li>
            </ol>
            <p>（1）不得删除出海易平台及其他副本上所有关于版权的信息、内容；<br />（2）不得对本出海易平台进行反向工程、反向汇编、反向编译等；<br />（3）对于出海易平台上的图像、文字等相关信息，未经我们书面同意，商家不得擅自实施。</p>
            <ol start="2">
            <li>出海易平台中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归出海易所有，商家在使用中所产生的内容的知识产权归商家或相关权利人所有。</li>
            <li>除另有特别声明外，出海易平台提供及所依托软件的著作权、专利权及其他知识产权均归出海易所有。</li>
            <li>出海易所使用所有出海易相关图标及LOGO等商业标识，其著作权或商标权归出海易所有。</li>
            <li>上述及其他任何出海易包含的内容的知识产权均受到法律保护，其他未经出海易平台、商家或相关权利人许可的第三人，不得以任何形式进行使用或创造相关衍生作品。</li>
            <li>任何未经出海易书面同意及权利人许可的非法获取行为，均属违法侵权行为。您确认并同意，为及时、有效地保障您的合法权益，您授权出海易在发现您的合法权益（包括但不限于信息网络传播权、著作权等）可能受到侵害时，有权代为向涉嫌侵权的第三人采取法律手段进行维权，授权采取的法律手段包括但不限于发函警告、提起诉讼、申请仲裁、移送公安机关处理等。</li>
            </ol>
            <p><strong><strong>十、商家违约及处理</strong></strong></p>
            <p><strong><strong>1、发生如下情形之一的，视为您违约：</strong></strong></p>
            <p>（1）使用出海易平台服务时违反有关法律法规规定的；<br />（2）违反本协议（即本协议第二.3条）约定的。</p>
            <p>为适应行业发展和满足海量商家对高效优质服务的需求，您理解并同意，<strong><u><strong>出海易可在出海易平台规则中约定违约认定的程序和标准。</strong></u></strong></p>
            <p><strong><strong>2、违约处理措施</strong></strong></p>
            <p><strong><strong>【信息处理】</strong></strong>您在出海易平台上发布的信息构成违约的，<strong><u><strong>出海易可根据相应规则立即对相应信息进行删除、屏蔽处理。</strong></u></strong></p>
            <p><strong><strong>【行为限制】</strong></strong>您在出海易平台上实施的行为，或虽未在出海易平台上实施但对出海易平台及其商家产生影响的行为构成违约的，出海易<strong><u><strong>可依据相应规则对您执行中止向您提供部分或全部服务等处理措施。如您的行为构成根本违约的，出海易可查封您的账户，终止向您提供服务。</strong></u></strong></p>
            <p><strong><strong>【处理结果公示】</strong></strong><strong><u><strong>出海易可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在出海易平台上予以公示。</strong></u></strong></p>
            <p><strong><strong>3、赔偿责任</strong></strong></p>
            <p><strong><u><strong>如您的行为使出海易及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿出海易及/或其关联公司的上述全部损失。</strong></u></strong></p>
            <p><strong><u><strong>如您的行为使出海易及/或其关联公司遭受第三人主张权利，出海易及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。</strong></u></strong></p>
            <p><strong><strong>十</strong></strong><strong><strong>一</strong></strong><strong><strong>、责任限制和免责</strong></strong></p>
            <ol>
            <li><strong><strong> 鉴于网络服务的特殊性，您同意出海易会变更、中断部分或全部的网络服务，并按本协议规定删除您在使用服务中提交的任何资料，而无需通知您，也无需承担任何责任。</strong></strong></li>
            <li><strong><strong> 您承诺向出海易提供的您方及您方客户资料（包括但不限于：姓名、地址、手机号等）真实、准确、完整及有效，符合中华人民共和国有关法律法规的规定，获得完全授权且不存在侵犯任何第三方合法权益的行为。若您冒用、盗用他人信息所导致的一切后果，由您个人承担。若因您的违法违规行为，导致出海易或/及百度公司陷入争议或诉讼纠纷，您应向出海易或/及百度公司赔偿一切实际损失。</strong></strong></li>
            <li><strong><strong> 您理解并同意，因业务发展需要，出海易保留单方面对平台全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销的权利，商家需承担此风险。</strong></strong></li>
            <li><strong><strong> 商家在使用本软件时，须自行承担如下出海易平台不可掌控的风险内容，包括但不限于：</strong></strong></li>
            </ol>
            <p>（1）由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等不可抗拒因素可能引起的信息丢失、泄漏等风险；<br />（2）商家或出海易平台的电脑软件、系统、硬件和通信线路出现故障；<br />（3）商家操作不当使用出海易平台；<br />（4）其他出海易无法控制或合理预见的情形。</p>
            <ol start="5">
            <li><strong><strong> 出海易依据本协议约定获得处理违法违规内容或行为的权利，该权利不构成出海易的义务或承诺，出海易不能保证及时发现违法违规情形或进行相应处理。</strong></strong></li>
            <li><strong><strong> 若出海易发现您因包括但不限于组织机构解散、注销、被吊销等任何原因导致民事行为能力、民事权利能力终止的情况，为保障商家和其他主体的合法权益，将有权决定立即终止您的出海易服务，且无需承担任何责任。</strong></strong></li>
            </ol>
            <p><strong><strong>十</strong></strong><strong><strong>二</strong></strong><strong><strong>、通知</strong></strong></p>
            <ol>
            <li>您在注册成为出海易平台的商家并接受出海易服务时，您应当准确填写并有义务及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便出海易平台或其他商家与您进行有效联系，因通过这些联系方式无法与您取得联系，导致您在使用出海易服务过程中产生任何损失或增加费用的，应由您完全独自承担。</li>
            <li>您需了解，商家所有的通知均可通过网页公告、账号后台通知、电子邮件、手机短信、客服电话或常规的信件传送等方式进行；您在注册出海易商家时生成的用于登陆出海易平台接收站内信、系统消息等即时信息的账户，亦作为您的有效联系方式。该等通知于发送之日视为已送达收件人。</li>
            <li><strong><strong> 百度将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</strong></strong></li>
            <li>您需了解，出海易平台发出的任何通知及各类规则构成本协议的一部分，并视为于发送或在本站公布之日已送达收件人并生效。如商家在各类规则发送或公布后继续使用本站服务的，视为商家同意该通知或规则的内容。如商家因任何原因没有阅读相关通知或规则的，出海易平台不承担由此产生的任何后果。</li>
            <li>您了解并同意，您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应按出海易平台的相关要求进行操作。</li>
            <li>对于在出海易上因贸易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在百度注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</li>
            <li>您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</li>
            <li>您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</li>
            <li>您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</li>
            </ol>
            <p><strong><strong>十</strong></strong><strong><strong>三</strong></strong><strong><strong>、协议的</strong></strong><strong><strong>变更、</strong></strong><strong><strong>终止</strong></strong></p>
            <ol>
            <li>协议的变更</li>
            </ol>
            <p>出海易可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称&ldquo;变更事项&rdquo;）将通过法定程序并以本协议第十三条约定的方式通知您。</p>
            <p>如您不同意变更事项，您有权于变更事项确定的生效日前联系出海易反馈意见。如反馈意见得以采纳，出海易将酌情调整变更事项。</p>
            <p><strong><strong>如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用出海易服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用出海易服务，则视为您同意已生效的变更事项。</strong></strong></p>
            <ol start="2">
            <li>终止的情形</li>
            </ol>
            <p>（1）您违反本协议约定，出海易依据违约条款终止本协议的；<br />（2）您盗用他人账户、发布违禁信息、骗取他人财物、采取不正当手段谋利等行为，出海易依据平台规则终止本协议，并保留追究您民事责任或刑事责任；<br />（3）除上述情形外，因您多次违反出海易平台规则相关规定且情节严重，出海易依据平台规则对您的账户予以查封的；<br />（4）您的账户被出海易依据本协议回收的；<br />（5）您在出海易平台所建独立站有欺诈、发布假冒伪劣/侵权商品、侵犯他人合法权益或其他严重违法违约行为的；<br />（6）其它应当终止服务的情况。</p>
            <ol start="3">
            <li>协议终止后的处理</li>
            </ol>
            <p><strong><strong>【商家信息披露】</strong></strong><strong><u><strong>本协议终止后，除法律有明确规定外，出海易无义务向您或您指定的第三方披露您账户中的任何信息。</strong></u></strong></p>
            <p>【出海易的权利】本协议终止后，出海易仍享有下列权利：</p>
            <p>（1）继续保存您留存于出海易平台的本协议第九条所列的各类信息；<br />（2）对于您过往的违约行为，出海易仍可依据本协议向您追究违约责任。</p>
            <p><strong><strong>十</strong></strong><strong><strong>四</strong></strong><strong><strong>、法律适用、管辖及其他</strong></strong></p>
            <ol>
            <li>本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。</li>
            <li>本协议由您与出海易平台在北京市海淀区签订。</li>
            <li>本协议无意结成或创设任何代理、合伙、合营、雇佣与被雇佣或特性授权与被授权关系。</li>
            <li>您同意爱出海易平台因经营业务需要有权将本协议项下的权利义务就部分或全部进行转让，而无须再通知予您并取得您的同意。</li>
            <li>因本协议或出海易服务所引起或与其有关的任何争议，由出海易与您协商解决。协商不成时，任何一方均可向本协议签订地人民法院提起诉讼。</li>
            <li>本协议取代您和出海易先前就相同事项订立的任何书面或口头协议。倘若本协议任何条款被裁定为无效或不可强制执行，该项条款应被撤销，而其余条款应予遵守和执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。出海易未就您或其他人士的某项违约行为采取行动，并不表明出海易撤回就任何继后或类似的违约事件采取行动的权利。</li>
            <li>本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</li>
            </ol>
            <p>再次感谢您对百度出海易的信任和使用！</p>
        `,
    },
    'en-US': {
        title: 'Overseadia Merchant Service Agreement',
        publishTime: '版本日期：2021-09-30',
        content: `
        <p>
            You are welcome to use the services provided by Chuhaiyi!
        </p>
        <p>
            jointly signed by you (hereinafter referred to as &quot;merchant&quot;) and Chuhaiyi . The terms and conditions set forth in this agreement apply to your use of the various services provided to you by Chuhaiyi, including the Chuhaiyi website (chuhaiyi.baidu.com), the Chuhaiyi SaaS backend (chuhaiyi.baidu.com/saas/ ) , etc. Various forms (including new service forms emerging from future technological development). &quot;Merchant&quot; refers to the institution or organization that logs in and uses various services/software of Chuhaiyi, and is also referred to as &quot;you&quot; in this agreement.
        </p>
        <p>
            <strong>1. Accept the agreement</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; The content of this agreement includes the following clauses and various rules that have been released or may be released in the future. All rules are an integral part of this agreement and have the same legal effect as the text of the agreement. You<br/>should read this agreement carefully before using the services of iShares. <strong>Please be sure to read carefully and fully understand the contents of each clause, especially the clauses on exemption or limitation of liability, applicable laws and dispute resolution clauses. Terms that exclude or limit liability will be underlined in bold and you should read them carefully. </strong>If you have any questions about the agreement, you can contact Chuhaiyi via email chuhaiyi@baidu.com. <strong>When you log in, you confirm that you have read, understood and accepted all the terms and rules of this agreement, and promise to abide by the provisions of various laws, regulations and policies in China and the countries involved in your business . In the event of any legal consequences, you will independently assume all corresponding legal responsibilities in your own name.</strong>
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>The platform has the right to amend this agreement from time to time according to the requirements of relevant laws, regulations, policies and the needs of providing services, or formulate and modify various specific rules according to this agreement and publish them in the relevant sections of the platform . Please pay attention to this agreement in due time. and changes to specific rules. If you continue to use the services/software of this platform after the announcement of changes in this agreement and specific rules, it means that you have read, understood and accepted the revised agreement and specific rules, and will also abide by the revised agreement and specific rules. Rules for the use of various services/software of Chuhaiyi; at the same time, with regard to your business activities and effectiveness through the Chuhaiyi platform before the revision of the agreement and specific rules, it is deemed that you have agreed and have carried out corresponding actions in accordance with this agreement and relevant rules. Authorization and ratification. If you do not agree with the revised agreement content, you should immediately stop using the relevant services of Chuhaiyi.</strong>
        </p>
        <p>
            <strong>2. Scope of the agreement</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; <strong>This agreement is jointly concluded by you and Chuhaiyi, and this agreement has contractual effect between you and Chuhaiyi.</strong>
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>After you confirm this agreement by yourself or authorizing the relevant parties in accordance with this agreement and the relevant rules and instructions of the platform, this agreement will have legal effect between you and the platform. This agreement does not involve any legal relationship or legal disputes between you and other merchants or users of this platform.</strong>
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; Due to the rapid development of the Internet, the terms set out in this agreement signed by you and Chuhaiyi cannot completely list and cover all your rights and obligations with us, and the existing agreements cannot guarantee that they fully meet the needs of future development. <strong>Therefore, all the rules in the chuhaiyi (chuhaiyi.baidu.com) station are supplementary agreements to this agreement, which are inseparable from this agreement and have the same legal effect. If you continue to use the service content of the platform, it is deemed that you agree to the above supplementary agreement.</strong>
        </p>
        <p>
            <strong>3. Definition</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; <strong>Chuhaiyi: </strong>It refers to providing a one-stop diversified overseas marketing solution service platform for Chinese exporters, aiming to help Chinese exporters to obtain overseas business opportunities and efficiently carry out cross-border business, including the website of Chuhaiyi (chuhaiyi.baidu.com) PC terminal and the mobile terminal, APP, etc. that may be developed later.
        </p>
        <p>
            2. Chuhaiyi service: Chuhaiyi is based on the Internet, and provides various services to you in various forms including the Chuhaiyi website (including new service forms emerging from future technological development). The specific services you enjoy are subject to the services you actually choose and pay for . The services listed in this agreement are all services provided by the platform as a whole, which does not mean that you can use all types of services.
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; Rules of the Easy Shipping Platform: Including all the rules, interpretations, announcements, etc. that have been published and subsequently released in all the Rules Channels of the Easy Shipping Platform.
        </p>
        <p>
            <strong>4. Account registration and use</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; <strong>Merchant Eligibility</strong>
        </p>
        <p>
            the civil capacity for your actions as stipulated by the laws of the People&#39;s Republic of China . <strong>If you do not have the aforementioned civil capacity suitable for your behavior , you and your guardian shall bear the corresponding consequences arising therefrom in accordance with the law, and EasyShare has the right to terminate the service provided to you and cancel your account. </strong>If you are registering with EasyShare on behalf of a company or other legal entity, you represent and warrant that you have the right to make the company or the legal entity bound by this agreement.
        </p>
        <p>
            2) In addition, you also need to ensure that you are not the subject of trade restrictions, sanctions or other laws and regulations imposed by any country, international organization or region, otherwise you may not be able to register and use the service normally.
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>account registration</strong>
        </p>
        <p>
            the services of Easy Access in other ways permitted by the Easy Access Platform , you are bound by this Agreement.
        </p>
        <p>
            2) Since your account is associated with your information and the business information of the Easy Shipping platform, your account is only used by you. Without the consent of GOSHOP, your act of directly or indirectly authorizing a third party to use your account or obtain the information under your account is invalid. If Chuhaiyi determines that the use of your account may endanger your account security and/or information security based on your account usage behavior , Chuhaiyi may refuse to provide corresponding services or terminate this agreement.
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; Certification
        </p>
        <p>
            In order to enable you to better use the various services of Chuhaiyi, Chuhaiyi may require you to complete authenticity certification or other real-name authentication in accordance with the regulations of Chuhaiyi or the provisions of national laws and regulations.
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; Registration Information Management
        </p>
        <p>
            1) When completing the registration, you shall provide and promptly update your information in an accurate and complete manner so that it remains true, timely, complete and accurate at all times. Under the circumstance that the national laws and regulations clearly stipulate that the platform service provider must verify the information of the merchants, Chuhaiyi will check and verify your information from time to time in accordance with the law, and you should cooperate with providing the latest, true, complete, valid information.
        </p>
        <p>
            2) If Chuhaiyi fails to contact you according to the information you provided the last time, you fail to provide information in a timely manner as required by Chuhaiyi, the information you provide is obviously false, or the administrative and judicial organs verify that the information you provide is invalid, you will Bear all the losses and adverse consequences caused to you, others and Sea Easy.
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; account security
        </p>
        <p>
            1) Your account is kept by you, and Chuhaiyi will not take the initiative to ask you to provide your account password at any time. Therefore, it is recommended that you always take good care of your account and ensure that you log out at the end of each internet session and leave with the correct steps. For the losses and consequences caused by your active disclosure of your account or because you are attacked, defrauded, etc. by others, EasyShare is <strong>not responsible, and you should seek compensation from the infringer through judicial, administrative and other relief channels.</strong>
        </p>
        <p>
            <strong>2) </strong>Except for the fault of Chuhaiyi, <strong>you shall be responsible for all the behavior results under your account (including but not limited to signing various agreements online , publishing information, sending emails, building an independent website, disclosing information, etc.).</strong>
        </p>
        <p>
            3) You must access or use the various services/software of Chuhaiyi through the Chuhaiyi platform , and Chuhaiyi will not assume any responsibility for the consequences of logging in and using other means. After you fill in the account number and password as required and use it, you are bound by this agreement. You should protect the security of your own password, and take full responsibility for all the behaviors used under the merchant account , including but not limited to the behavior of the merchant itself and any third party under the merchant account .
        </p>
        <p>
            4) If you find that the account is used without the authorization of the merchant, you should immediately notify Chuhaiyi. This website has the right to copy and save your information on its server, notify related companies or platforms, and use it to contact you for your benefit. You should understand that it will take reasonable time for Chuhaiyi to take action on any of your requests, and the actions that Chuhaiyi takes in response to your request may not be able to avoid or prevent the formation or expansion of the consequences of infringement. In the case of theft or loss, Chaiyi does not assume responsibility.
        </p>
        <p>
            5) You understand and agree that, in order to help you better use all services/software of Chuhaiyi Platform, Chuhaiyi Platform has the right to obtain the following information about you and your users: all kinds of information generated in the process of enjoying various services . You agree to authorize Chuhaiyi Platform and its partners to use the above information to conduct relevant analysis, improve the content of various products of Chuhaiyi Platform, and make the content, design and services of related products/services more in line with the needs of merchants.
        </p>
        <p>
            account on the chuhaiyi platform does not mean that he has obtained the authorization of all Baidu products, but only the identity of the service/software of the chuhaiyi platform. The merchant needs to sign separately when logging in to Baidu&#39;s relevant webpages, loading applications, and downloading and installing software. License agreement for a single product.
        </p>
        <p>
            account information you fill in and submit, the platform will display your registration information to other users within the scope of the law and the agreement. If the service or content you provide needs to be licensed or filed under relevant laws and regulations, you should make a clear explanation and submit the corresponding legal and valid license or filing certificate during account registration and authentication. Otherwise, the platform has the right to refuse or terminate the provision of this service, and punish the violating account in accordance with this agreement. Therefore, if the damage caused to Haiyi and its affiliates or a third party is caused, you shall compensate according to law. If there is any change in the above information, you should immediately submit the changed information to the platform in writing. Before the change of information is verified, the platform can completely rely on the information before the change to act, and all risks arising therefrom shall be borne by you. If Chuhaiyi platform finds out by itself, or a third party notices that the above information you provide is untrue or inaccurate, Baidu has the right to terminate the provision of this service to you, and you shall bear all legal responsibilities arising therefrom.
        </p>
        <p>
            <strong>make a reasonable and prudent formal evaluation and judgment within the legal authority and ability of Chuhaiyi based on the information you submit, including but not limited to registration information, service categories, instructions for use, etc. Serve. However, Baidu cannot substantively examine the actual operation, operation and promotion of the merchant, and does not provide any guarantee for this; nor should it be identified as the co-provider of the merchant&#39;s behavior or the merchant&#39;s content due to its review behavior, and neither such review and assume no responsibility for your conduct and content. You should read the relevant agreements, norms and rules carefully. Due to various factors such as constantly changing laws and regulations, regulatory requirements, product specifications and user experience needs, some of your products and software functions may be suspended or stopped.</strong>
        </p>
        <p>
            service /software development and merchant review of the Chuhaiyi platform will impose human and economic burdens on the Chuhaiyi platform. If you are malicious or non-malicious (such as technical level, merchants, etc. Demand, etc.) cause the information content to be submitted too frequently, Chuhaiyi and/or its affiliates have the right to take measures against you , such as requesting payment of service fees/margins, suspending or terminating the release of information content to you, and the resulting responsibilities shall be yours bear alone.
        </p>
        <p>
            10) You clearly understand and agree that the content of the information you upload, manage and publish through the Chuhaiyi platform should be within the categories permitted by law and the Chuhaiyi platform , and must be filed in your approved business scope and legal qualifications within the range. To this end, you should manage your information content truthfully, comprehensively, and without omissions according to the services you provide, the activities you engage in, and the industry you are in. If the platform finds that the qualification materials you submit do not meet the qualification materials requirements of the relevant industry, it will suspend or stop your account permissions. At the same time, based on the laws, regulations or regulatory requirements of a specific industry and specific field, the platform may also require you to provide additional relevant qualification materials according to the changing reality. You should cooperate in providing this, otherwise the platform has the right to refuse to provide you Provide services.
        </p>
        <p>
            <strong>5. Specifications for business use</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; <strong>You understand and agree that you shall not produce, reproduce, publish, disseminate, interfere with the normal operation of the Chuhaiyi platform and infringe the legitimate rights and interests of other merchants/users or third parties, including but not limited to the following behaviors: use, copy, modify, link, Reprint, compile, publish, publish, establish mirror sites, and develop derivative products and services related to it through the platform without authorization.</strong>
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>You understand and agree that you should submit or upload company information and product information through the Easy Shipping Platform in accordance with the rules of the Easy Shipping Platform and publish it to your independent website, including but not limited to the company name, place of shipment, contact name, contact person Mobile phone number, landline number, email, fax number, product information introduction, etc., and agree that Chuhaiyi platform has the right to directly display the aforementioned information you submit on Chuhaiyi related products in accordance with the platform rules, including but not limited to Chuhaiyi platform’s PC terminal and other related products that go to sea easily .</strong>
        </p>
        <ol class=" list-paddingleft-2">
            <li>
                <p>
                    <strong>您理解并同意，因您使用出海易服务时可能会涉及数据的跨境传输、使用等情况，您是数据控制者，出海易作为数据处理者仅会基于您的请求提供数据处理服务，您应确保已经就出海易作为处理者处理您的海外用户的数据获得用户授权，同时您需确保按照数据输出国的要求进行数据跨境传输，遵守相应国家的法律法规规定，按照相应国家要求进行审批或备案。其中，涉及从中华人民共和国出境的数据，请严格遵守《中华人民共和国网络安全法》、《中华人民共和国数据安全法》、《中华人民共和国个人信息保护法》等相关法律规定，并根据数据出境情况进行相关审批。</strong>
                </p>
            </li>
        </ol>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; <strong>You may not publish, transmit, disseminate, or store content that violates national laws and regulations, including but not limited to:</strong>
        </p>
        <p>
            (1) Violating the basic principles established by the Constitution;<br/>(2) Endangering national security, divulging state secrets, subverting state power, and undermining national unity;<br/>(3) Injuring national honor and interests;<br/>(4) Inciting ethnic hatred and ethnic discrimination , destroying national unity;<br/>(5) Undermining national religious policies, advocating cults and feudal superstitions;<br/>(6) Spreading rumors, disrupting social order, and undermining social stability;<br/>(7) Spreading obscenity, pornography, gambling, violence, terror or (8) Insulting or slandering others, infringing on the lawful rights and interests of others; (9) Inciting<br/>illegal<br/>assemblies, associations, processions, demonstrations, or gatherings to disrupt social order;<br/>(10) Acting in the name of illegal civil organizations;<br/>(11) Contains other content prohibited by laws and administrative regulations.
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; You shall not publish, transmit, disseminate, or store content that infringes upon the legal rights of others such as reputation, portrait, intellectual property, trade secrets, and privacy.
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; You may not post, transmit, or transmit harassing, spam, or content of any kind that is sexual or sexually suggestive.
        </p>
        <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; You shall not publish, transmit, or disseminate any violation of laws, regulations, policies, public order and good morals, social morality, or interfere with the normal operation of the platform and violate other merchants /users or Information on the content of the legitimate interests of third parties. You are not allowed to use this platform in any form as a venue, platform or medium for engaging in various illegal activities.
        </p>
        <p>
            7.&nbsp;&nbsp;&nbsp;&nbsp; We reserve the right to provide you with the right to modify and upgrade the platform at any time, and to charge fees for the modification or upgrade service.
        </p>
        <p>
            8.&nbsp;&nbsp;&nbsp;&nbsp; Chuhaiyi platform hereby solemnly reminds merchants that all kinds of information content developed, created, uploaded, edited and published by merchants through Chuhaiyi platform are all behaviors and information content developed, created, uploaded, edited and published by merchants. at your own risk. Chuhaiyi cannot control the content displayed by the merchant, nor can it fully control the behavior of the merchant, so it cannot guarantee the legality, correctness, integrity, relevance, authenticity or quality of the merchant content; if the merchant content may expose others to Unpleasant, inappropriate or even illegal information, all relevant risks are borne by the merchants themselves, and have nothing to do with the platform. If a merchant spreads and disseminates reactionary, pornographic or other information that violates national laws and regulations, the various types of information released by the merchant may be used as evidence that the merchant violates the law.
        </p>
        <p>
            9.&nbsp;&nbsp;&nbsp;&nbsp; You shall not use any illegal means to obtain the personal information of other merchants or users, shall not use the information of other merchants/users for any profit or non-profit purpose, and shall not disclose the personal information of other merchants or rights holders. Reasonable measures stipulated in this agreement to stop your above-mentioned behaviors, if the circumstances are serious, it will be submitted to the public security organs for criminal case.
        </p>
        <p>
            10. You shall not publish, transmit, disseminate, store, or infringe any content that infringes upon others&#39; copyright, trademark and other intellectual property rights, trade secrets, or other legal rights (such as portrait rights and privacy rights); for reprinted information, you should indicate the reprint and The source, indicate the author, and respect the copyright of the author; if other merchants or rights holders find that the information you publish is suspected of intellectual property rights or other legitimate rights and interests disputes, these merchants or rights holders have the right to request the platform to delete your published information, Or take other necessary measures to stop it, and the platform will take these measures in accordance with the law. You are solely responsible for all the responsibilities arising from your tortious acts, and have nothing to do with this platform.
        </p>
        <p>
            11. <strong>You agree that if you use this platform to provide services to any third party, this platform will not be responsible for any disputes between you and the third party.</strong>
        </p>
        <p>
            12. <strong>You undertake to take full responsibility for all legal consequences arising from the activities you undertake through the platform . If Chuhaiyi Platform, Baidu Company or any third party bears relevant responsibilities due to your use of Chuhaiyi Platform, you shall fully compensate Chuhaiyi Platform, Baidu Company or any third party for related expenses and losses , including reasonable attorneys&#39; fees.</strong>
        </p>
        <p>
            13. <strong>If you violate any of the terms of this agreement and cause losses to Haiyi, Baidu or other merchants, you must bear all the responsibilities for compensation. If Chuhaiyi and/or Baidu assume the above responsibilities, you agree to indemnify Chuhaiyi and/or Baidu for related expenses and losses, including reasonable attorney fees.</strong>
        </p>
        <p>
            14. <strong>You should pay attention to and abide by the various rules and regulations regularly announced or revised by the platform from time to time. Chuhaiyi platform reserves the right to delete all kinds of information content that does not comply with legal policies or untrue information on the site without notifying you.</strong>
        </p>
        <p>
            <strong>6. Easy to go overseas service</strong>
        </p>
        <p>
            You have the right to enjoy services such as browsing the content and information of the official website of Chuhaiyi, uploading and publishing information on independent website establishment, lead management, email management, online promotion and other services on Chuhaiyi. The services you actually enjoy are subject to the services provided by the products you choose and order.
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; <strong>Browse the content and information of the official website of chuhaiyi</strong>
        </p>
        <p>
            You can browse the content and information of Chuhaiyi &#39;s official website , log in to Chuhaiyi&#39;s background after registering an account, and learn about Chuhaiyi&#39;s related products and services. You can also leave a message and contact information, so that the third-party service provider we cooperate with can get in touch with you and introduce you to the relevant products and services of Chuhaiyi.
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>Independent station establishment and information upload and release</strong>
        </p>
        <p>
            1) Through the independent website construction technical services provided on the Chuhaiyi platform, you can build your own independent website, upload and publish various product and/or service information on your independent website, and you can manage your own independent website and operation.
        </p>
        <p>
            2) Through the services provided by the Chuhaiyi platform, you have the right to publish product and/or service information on your independent website in the form of text, pictures, etc.
        </p>
        <p>
            3) You should ensure that you have the corresponding rights to the products and/or services you publish on the independent website, and you are not allowed to publish or display the following products and/or services on the independent website and the e-commerce platform:
        </p>
        <p>
            <strong>(1) Prohibited or restricted by the state;<br/>(2) Infringing the intellectual property rights or other legitimate rights and interests of others;<br/>(3) The rules, announcements, notices or agreements signed by each platform and you have clearly stated that it is not suitable for going out to sea. uploaded, published or displayed on the Easy Platform.</strong>
        </p>
        <p>
            4) You should abide by the principle of good faith, ensure that the product and/or service information you publish is true and consistent with the products and/or services you actually provide, and fulfill your commitments in the process of trading with users. You shall not interfere or attempt to interfere with the normal operation of the platform in any way.
        </p>
        <p>
            5) Paying taxes in accordance with the law is the obligation of every citizen and enterprise. You should declare and pay taxes to the tax authorities in a timely and full amount in accordance with the requirements of the law.
        </p>
        <p>
            <strong>6) Chuhaiyi has the right to check your registration data, operation behavior and related data, and if any problem is found in the registration data, operation behavior and data, it has the right to send you a notice of inquiry or correction, or delete it directly and so on.</strong>
        </p>
        <p>
            7) In order to help merchants improve content quality, the platform may provide functional modules for merchants to optimize content quality and improve network communication efficiency. The functional modules provided by the platform include but are not limited to customer management, content management, independent site management, email management, etc. Different functional modules have different usage requirements and standards. The text, pictures and other types of content generated by merchants using various functional modules are collectively referred to as &quot;merchant content&quot;. <strong>According to changes in business needs, technological development, business adjustment, etc., the platform increases, decreases, and adjusts various functions and related products and services in real time. The actual available functions and functional requirements are subject to the platform display.</strong>
        </p>
        <p>
            8) Some functional modules may provide pictures or text materials for merchants to learn and research. Merchants can modify the content of these materials at any time. Chuihaiyi cannot guarantee the relevance and accuracy of these materials and the actual situation or actual needs of the merchants. Merchants are requested to carefully review the final displayed content to ensure that it does not violate any effective laws and regulations and is consistent with the actual situation of the merchants.
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; Lead management, you can add new leads in lead management by creating, uploading, importing, obtaining leads from independent stations, etc., and follow-up management of leads by changing status, adding notes, etc.
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; manage your emails through functions such as email status change and deletion in the email module .
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; For online promotion, you provide relevant promotion materials by yourself, and provide online promotion services for you through the services provided by Chuhaiyi and third-party online promotion companies.
        </p>
        <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; <strong>data storage</strong>
        </p>
        <p>
            <strong>For the content published or stored by merchants on the platform, merchants should make backups on their own from time to time. Chuhaiyi is not responsible for your deletion or failure to store relevant data in this software. Chuhaiyi has the right to determine the maximum storage period of data in this software for a single merchant according to the actual situation, and allocate the maximum data storage space for it on the server. You can back up the relevant data in this software according to your own needs. If you stop using EasyShare or the service is terminated or cancelled, EasyShare and/or its affiliates may permanently delete your data from the server. After the service is stopped, terminated or cancelled, Baidu is not obliged to return any data or content of this platform to you.</strong>
        </p>
        <p>
            <strong>7. Expenses</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; Baidu has paid a lot of costs for the services provided to you by Chuhaiyi Platform. Except for the charging business expressly stated by Chuhaiyi Platform, other services provided to you by Chuhaiyi Platform are currently free. If EasyShare charges you reasonable fees in the future, EasyShare will take reasonable measures and pass legal procedures in advance within a sufficiently reasonable period of time and notify you in the manner stipulated in this agreement to ensure that you have the right to fully choose.
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>For the charging business expressly stated on the Chuhaiyi platform, if Chuhaiyi is unable to perform the agreed services normally due to you or your reasons , Chuhaiyi does not assume any responsibility, and cannot change the service period or refund for you.</strong>
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; All the taxable taxes, as well as the related hardware, software, communication, network service and other expenses incurred by you for conducting transactions, obtaining paid services from Chuhaiyi or accessing the Chuhaiyi server shall be borne by you.
        </p>
        <p>
            <strong>8. Protection and authorization of business information</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; Protecting the personal information of merchants is a basic principle of Baidu, and Baidu will take reasonable measures to protect the personal information of merchants. Except for the circumstances stipulated by laws and regulations, Baidu will not disclose or disclose the personal information of merchants to third parties without the permission of the merchants. When you use the services provided by Chuhaiyi , you agree that Baidu will collect, store, use, disclose and protect your personal information in accordance with the &quot;Privacy Policy&quot; published on the Chuhaiyi platform. Baidu adopts professional encrypted storage and transmission methods for relevant information to ensure the safety of merchants&#39; personal information.
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>You need to fill in some necessary information in the process of applying for Easy Shipping service . Please keep the information true, accurate, legal, valid and update it in time. At the same time, you understand and agree that you authorize Easy Shipping to log in and use your account using your account. The information provided and formed in the service process of Easy Shipping is passed to other Baidu related platforms, so that Baidu can provide you with timely and effective help or provide you with better services. According to relevant laws, regulations and policies, please fill in your real identity information. If the information you fill in is incomplete or inaccurate, you may not be able to use the service or be restricted in the use process. You understand and agree that in order to provide services to you, it is necessary to display your registration information to other merchants/users to the extent permitted by law.</strong>
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; You represent and warrant that you have corresponding and legal rights to the information you publish. <strong>Otherwise, the website may delete or block the information you publish on the website in accordance with the law or this agreement.</strong>
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; In the process of using this platform, you should strictly abide by the applicable laws and regulations on the protection of user information, personal information and privacy rights and the protection of network security operation. Your products and services have the ability to collect personal information (including personal information containing personal sensitive information, where &quot;personal sensitive information&quot; for example: reflects personal property status, personal health and physiology, personal identity, personal biometric identification, network identification, personal phone number , communication records and content, location status data, and minors’ personal information) function, shall follow the principles of legality, legitimacy, and necessity, and clearly state the purpose, method and scope of the collection and use of information to the subject of the collected information, and through The subject of the collected information expressly consents.
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; Baidu will use various security technologies and procedures to establish a sound management system to protect your personal information from unauthorized access, use or disclosure.
        </p>
        <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; Without your consent, Baidu will not disclose your personal information to any company, organization or individual other than Baidu, unless otherwise provided by laws and regulations.
        </p>
        <p>
            7.&nbsp;&nbsp;&nbsp;&nbsp; Baidu attaches great importance to the protection of minors&#39; personal information. If you are a minor under the age of 18, you should obtain the written consent of your parent or legal guardian in advance before using the services of Chuhaiyi.
        </p>
        <p>
            8.&nbsp;&nbsp;&nbsp;&nbsp; You represent and warrant that you have corresponding and legal rights to the information you publish. Otherwise, iShares may delete or block the information you publish in accordance with the law or this agreement.
        </p>
        <p>
            9.&nbsp;&nbsp;&nbsp;&nbsp; For non-personal information such as text, pictures, videos, audios and other non-personal information other than personal information that you provide, publish and form in the use of Chuhaiyi services , within the protection period stipulated by law, you grant Chuhaiyi and Baidu to obtain global exclusive rights free of charge. Licensing the right to use and the right to sub-license to other third parties and to obtain evidence and bring lawsuits against third-party infringements in its own name. You agree that Haiyi and Baidu will store, use, copy, revise, edit, publish, display, translate, distribute your non-personal information or make derivative works thereof, and transfer the above-mentioned information in known or later developed forms, media or technologies. Information incorporated into other works.
        </p>
        <p>
            10. You promise to encrypt and store the personal information obtained from the Chuhaiyi platform and authorized by the information subject, and use the personal information within the scope of the information subject&#39;s authorization, and shall not use or share it without the express consent of the information subject or beyond the scope of his consent. , transfer and disclose the personal information of the information subject; you ensure that you can establish sufficient data security capabilities and take necessary management and technical measures, implement personal information security protection measures in accordance with national standards, and prevent the leakage, damage and loss of personal information. At the same time, you may not collect, process, store, capture or obtain in any way the personal information of the information subjects controlled by Baidu without Baidu&#39;s permission or beyond the scope of Baidu&#39;s authorization .
        </p>
        <p>
            11. If you violate the above personal information protection clauses or Chuhaiyi judges that your collection , processing, use, and sharing of personal information may damage the rights and interests of the subject of personal information, you should immediately delete the relevant personal information according to the requirements of Chuhaiyi, and no longer Collect, process, store and use the above personal information data.
        </p>
        <p>
            <strong>9. Intellectual property rights</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; Merchants can use the platform in accordance with this agreement on the premise of abiding by the law and this agreement. Merchants do not have the right to perform actions including but not limited to the following:
        </p>
        <p>
            (1) All copyright information and content on the Chuhaiyi platform and other copies shall not be deleted;<br/>(2) The Chuhaiyi platform shall not be reverse engineered, reverse assembled, decompiled, etc.;<br/>(3) For the Chuhaiyi platform Without our written consent, the merchant shall not implement it without authorization.
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; The intellectual property rights of the content provided in the Chuhaiyi platform (including but not limited to web pages, text, pictures, audio, video, graphics, etc.) belong to Chuhaiyi, and the intellectual property rights of the content generated by the merchants in the use belong to the merchants or related rights holders. all.
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; Unless otherwise specified otherwise, the copyright, patent rights and other intellectual property rights of the software provided and relied on by the Chuhaiyi platform are owned by Chuhaiyi.
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; All the relevant icons and LOGOs of Chuhaiyi used by Chuhaiyi, whose copyright or trademark rights belong to Chuhaiyi.
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; The intellectual property rights of the above-mentioned and any other content contained in the platform are protected by law, and other third parties without the permission of the platform, merchants or relevant rights holders shall not use or create related derivative works in any form.
        </p>
        <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; Any illegal acquisition without the written consent of Chuhaiyi and the permission of the rights holder is an illegal tort. You confirm and agree that, in order to protect your legitimate rights and interests in a timely and effective manner, you authorize Chuhaiyi to have the right to represent the suspected infringement when it discovers that your legitimate rights and interests (including but not limited to information network dissemination rights, copyrights, etc.) may be infringed. The authorized third party protects their rights by legal means, including but not limited to sending a letter of warning, filing a lawsuit, applying for arbitration, and transferring it to a public security organ for processing.
        </p>
        <p>
            <strong>X. Merchant breach of contract and handling</strong>
        </p>
        <p>
            <strong>1. If one of the following circumstances occurs, you shall be deemed to be in breach of contract:</strong>
        </p>
        <p>
            (1) Violating the provisions of relevant laws and regulations when using the services of the China Express Platform;<br/>(2) Violating the provisions of this agreement (that is, Article 2.3 of this agreement).
        </p>
        <p>
            In order to adapt to the development of the industry and meet the needs of a large number of merchants for efficient and high-quality services, you understand and agree that <span style="text-decoration: underline;"><strong>Chuhaiyi can stipulate the procedures and standards for default determination in the rules of the Chuhaiyi platform.</strong></span>
        </p>
        <p>
            <strong>2. Measures to deal with breach of contract</strong>
        </p>
        <p>
            <strong>[Information processing] If the information </strong>you publish on the Chuhaiyi platform constitutes a breach of contract, <strong><span style="text-decoration:underline;">Chuhaiyi can immediately delete or block the corresponding information according to the corresponding rules.</span></strong>
        </p>
        <p>
            <strong>[Behavior Restrictions] </strong>If your actions on the Chuhaiyi platform, or the behaviors that have not been implemented on the Chuhaiyi platform but have an impact on the Chuhaiyi platform and its merchants constitute a breach of contract, Chuhaiyi <strong><span style="text-decoration:underline;">may suspend the execution of your actions in accordance with the corresponding rules. You provide some or all of the services and other processing measures. If your behavior constitutes a fundamental breach of contract, Easy can seal up your account and terminate the provision of services to you.</span></strong>
        </p>
        <p>
            <strong>[Announcement of processing results] <span style="text-decoration:underline;">Chuhaiyi may publicize your information on the above-mentioned breach of contract measures and other illegal information confirmed by the effective legal documents of the national administrative or judicial organs on the Chuhaiyi platform.</span></strong>
        </p>
        <p>
            <strong>3. Liability for compensation</strong>
        </p>
        <p>
            <strong><span style="text-decoration:underline;">If your actions cause losses to Chuhaiyi and/or its affiliates (including its own direct economic losses, loss of goodwill, and indirect economic losses such as compensation, settlement, attorney fees, litigation fees, etc. paid to the outside world), you should compensate All the above losses of Chuhaiyi and/or its affiliates.</span></strong>
        </p>
        <p>
            <strong><span style="text-decoration:underline;">If your actions cause the third party to claim the rights of the company and/or its affiliates, the company and/or its affiliates can recover all losses from you after assuming the obligation of monetary payment to the third party.</span></strong>
        </p>
        <p>
            <strong>11. Limitation of Liability and Disclaimer</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; <strong>delete any information you submit in the use of services in accordance with the provisions of this agreement , without notifying you or taking any responsibility.</strong>
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>You promise that the information about you and your customers (including but not limited to: name, address, mobile phone number, etc.) provided to Chuhaiyi is true, accurate, complete and valid, in line with the relevant laws and regulations of the People&#39;s Republic of China, and is fully authorized and There is no violation of the legitimate rights and interests of any third party. If you fraudulently use or misappropriate other people&#39;s information, you will be responsible for all the consequences. If your violations of laws and regulations lead to disputes or litigation disputes between Chuhaiyi and/or Baidu, you should compensate Chuhaiyi or/and Baidu for all actual losses.</strong>
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; <strong>You understand and agree that due to the needs of business development, Chuhaiyi reserves the right to unilaterally change, suspend, limit, terminate or revoke all or part of the service content of the platform at any time without any notice, and the merchant shall bear this risk.</strong>
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; <strong>When using this software, merchants must bear the following risks that are beyond the control of the platform, including but not limited to:</strong>
        </p>
        <p>
            computer viruses, Trojan horses or other malicious programs, hacker attacks, etc.;<br/>(3) Improper use of the platform by merchants;<br/>(<br/>4) other circumstances beyond the control of or reasonably foreseeable by the merchant.
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; <strong>According to this agreement, Chuhaiyi obtains the right to deal with illegal content or behavior. This right does not constitute an obligation or commitment of Chuhaiyi. Chuhaiyi cannot guarantee that violations of laws and regulations will be discovered in a timely manner or dealt with accordingly.</strong>
        </p>
        <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; <strong>If Chuhaiyi finds that your capacity for civil conduct and capacity for civil rights has been terminated for any reason including but not limited to the dissolution, cancellation, revocation of the organization, etc., in order to protect the legitimate rights and interests of merchants and other subjects, it will have the right to decide to terminate your service immediately. Easy to serve overseas without any responsibility.</strong>
        </p>
        <p>
            <strong>12. Notice</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; When you register as a merchant on the Chuhaiyi platform and accept the services of Chuhaiyi The merchant has an effective contact with you, and you should be solely responsible for any loss or increase in costs incurred during the use of the Easy Shipping service due to the inability to get in touch with you through these contact methods .
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; You need to understand that all notifications of merchants can be made through webpage announcements, account background notifications, emails, mobile phone text messages, customer service calls or regular mail transmission; The account of the platform to receive instant information such as in-station messages and system messages is also used as your effective contact information. Such notices are deemed to have been delivered to recipients on the date they are sent.
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; <strong>Baidu will deliver various notices to you on one or several of your above- mentioned contact information, and the content of such notices may have a significant favorable or unfavorable impact on your rights and obligations, please pay attention in time.</strong>
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; You need to understand that any notices and various rules issued by the platform form part of this agreement, and are deemed to have been delivered to the recipient and take effect on the date of sending or announcement on this site. If the merchant continues to use the services of this site after various rules are sent or announced, the merchant shall be deemed to agree to the content of the notice or rule. If the merchant does not read the relevant notices or rules for any reason, the platform does not bear any consequences arising therefrom.
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; You understand and agree that you are obliged to maintain the validity of the contact information provided by you. If there is any change and need to be updated, you should operate according to the relevant requirements of the platform.
        </p>
        <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; For any disputes arising from trade activities on Easy Shipping, you agree that judicial authorities (including but not limited to people&#39;s courts) may serve legal documents (including but not limited to, but not limited to, the people&#39;s courts) to you through modern communication methods such as mobile phone text messages, e-mails, or by mail. litigation documents). The mobile phone number, e-mail and other contact information you designate to receive legal documents are the mobile phone number and e-mail contact information you provided when registering or updating on Baidu. Legal documents issued by judicial organs to the above contact information are deemed to be served. The mailing address you specify is your legal contact address or a valid contact address you provide.
        </p>
        <p>
            7.&nbsp;&nbsp;&nbsp;&nbsp; You agree that the judicial authority may use one or more of the above delivery methods to deliver legal documents to you. The judicial authority may deliver legal documents to you by various methods. .
        </p>
        <p>
            8.&nbsp;&nbsp;&nbsp;&nbsp; You agree that the above methods of service apply to each stage of the judicial process. Such as entering the proceedings, including but not limited to the first instance, second instance, retrial, enforcement and supervision procedures.
        </p>
        <p>
            9.&nbsp;&nbsp;&nbsp;&nbsp; You should ensure that the contact information provided is accurate, valid and updated in real time. If the contact information provided is inaccurate, or the changed contact information is not notified in time, the legal documents cannot be delivered or are not delivered in time, you shall be responsible for the legal consequences that may arise therefrom.
        </p>
        <p>
            <strong>13. Modification and termination of the agreement</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; Changes to the Agreement
        </p>
        <p>
            Chuhaiyi may revise this agreement and supplementary agreement from time to time in accordance with changes in national laws and regulations and the need to maintain transaction order and protect consumer rights and interests. You will be notified in the manner stipulated in Article 13.
        </p>
        <p>
            If you do not agree with the changes, you have the right to contact GOSH for feedback before the effective date of the changes. If the feedback is adopted, Chaiyi will adjust the changes as appropriate.
        </p>
        <p>
            <strong>If you still do not agree with the changes that have taken effect, you should stop using Chuhaiyi services from the date when the changes are determined to take effect, and the changes will not have effect on you; if you continue to use Chuhaiyi after the changes take effect service, it is deemed that you agree to the effective changes.</strong>
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; Circumstances of termination
        </p>
        <p>
            ( 1) You violate this agreement, and Chuhaiyi terminates this agreement in accordance with the terms of the breach;<br/>( 2) If you embezzle other people&#39;s accounts, publish prohibited information, defraud others, take improper means to make profits, etc., Chuhaiyi will terminate according to the platform rules. This Agreement, and reserves the right to pursue your civil or criminal liability;<br/>(3) In addition to the above circumstances, because you repeatedly violated the relevant provisions of the platform rules and the circumstances are serious, the platform will seal up your account<br/>according to the rules of the platform; ( 4) Your account is recovered by Chuhaiyi in accordance with this agreement;<br/>(5) You have committed fraud, publishing fake and inferior/infringing goods, infringing on the legitimate rights and interests of others, or other serious violations of the law on the independent station you built on the Chuhaiyi platform;<br/>(6 ) ) other circumstances that should terminate the service.
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; Processing after termination of the agreement
        </p>
        <p>
            <strong>[Merchant Information Disclosure] <span style="text-decoration:underline;">After the termination of this agreement, except as expressly provided by the law, Chuhaiyi has no obligation to disclose any information in your account to you or a third party you designate.</span></strong>
        </p>
        <p>
            [Rights of Chuhaiyi] After the termination of this agreement, Chuhaiyi still enjoys the following rights:
        </p>
        <p>
            (1) Continue to save the various types of information listed in Article 9 of this Agreement that you have retained on the Chuhaiyi platform;<br/>(2) For your past breach of contract, Chuhaiyi may still hold you accountable for breach of contract in accordance with this Agreement.
        </p>
        <p>
            <strong>14. Applicable Law, Jurisdiction and Others</strong>
        </p>
        <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; The validity, interpretation, modification, execution and dispute resolution of this Agreement shall be governed by the laws of the Mainland of the People&#39;s Republic of China. If there are no relevant laws and regulations, general international business practices and/or industry practices shall be referred to.
        </p>
        <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; This agreement is signed by you and Chuhaiyi Platform in Haidian District, Beijing.
        </p>
        <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; This Agreement is not intended to form or create any agency, partnership, joint venture, employment-to-employment, or franchise-to-authority relationship.
        </p>
        <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; You agree that iChat has the right to transfer some or all of the rights and obligations under this agreement due to business needs, without the need to notify you and obtain your consent.
        </p>
        <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; Any disputes arising out of or related to this agreement or the services of iShares shall be resolved through consultation between iShares and you. If the negotiation fails, either party may file a lawsuit with the people&#39;s court in the place where this agreement is signed.
        </p>
        <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; This agreement supersedes any written or oral agreement between you and iShares previously entered into on the same matters. If any provision of this Agreement is found to be invalid or unenforceable, that provision shall be rescinded and the remaining provisions shall be complied with and enforced. The clause headings are for convenience only and do not in any way define, limit, interpret or describe the scope or limitations of the clause. My failure to take action against a breach of contract by you or other persons does not indicate that ICBC withdraws its right to take action on any subsequent or similar breach of contract.
        </p>
        <p>
            7.&nbsp;&nbsp;&nbsp;&nbsp; Any provision of this Agreement shall be deemed void, void or unenforceable, such provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions of this Agreement.
        </p>
        <p>
            Thank you again for your trust and use of Baidu Chuhaiyi!
        </p>
        <p>
            <br/>
        </p>
        `,
    },
};
